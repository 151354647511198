export class PurchaseRequest {
    RequestNumber:number;
    Date: Date;
    RequesterName:string;
    RequesterId:number; 
    RequestedItem:string;
    Cost:number;
    Budgeted:boolean;
    CapitalExpenditure:boolean;
    VendorName:string;
    ExpectedDeliveryDate:Date;
    PaymentTerms:string;
    Status:string;
    Note: string;
    constructor(
        RequestNumber:number,
        Date: Date,
        RequesterName:string,
        RequesterId:number,   
        RequestedItem:string,
        Cost:number,
        Budgeted:boolean,
        CapitalExpenditure:boolean,
        VendorName:string,
        ExpectedDeliveryDate:Date,
        PaymentTerms:string,
        Status:string,
        Note: string
    ) {
        this.RequestNumber= RequestNumber;
        this.Date = Date;
        this.RequesterName = RequesterName;     
        this.RequesterId = RequesterId;     
        this.RequestedItem = RequestedItem;
        this.Cost = Cost;
        this.Budgeted = Budgeted;
        this.CapitalExpenditure = CapitalExpenditure;
        this.VendorName = VendorName;
        this.ExpectedDeliveryDate = ExpectedDeliveryDate;
        this.PaymentTerms = PaymentTerms;
        this.Status = Status;
        this.Note = Note;
    }

    static getEmptyPurchaseRequest():PurchaseRequest{
        return new PurchaseRequest(null,new Date(),"",null,"",0,false,false,"",new Date(),"","Pending","");
    }
}