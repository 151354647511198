import { Component, OnInit, Output, EventEmitter, OnDestroy, Inject } from '@angular/core';
import { AppService } from '../../../service/app.service';
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { FormControl, FormsModule, Validators, ControlValueAccessor, ReactiveFormsModule, FormGroup } from '@angular/forms';
import { CustomValidationService } from '../../../service/customValidation.service';
import { Requester } from '../../../model/requester';

@Component({
  selector: 'app-requesters-upsert',
  templateUrl: './requesters-upsert.component.html',
  styleUrls: ['./requesters-upsert.component.scss']

})
export class RequestersUpsertComponent {

  public formControl: FormControl;
  description: string;
  requesterDataLoaded: Promise<boolean>;

  constructor(
    private appService: AppService,
    private dialogRef: MatDialogRef<RequestersUpsertComponent>,
    @Inject(MAT_DIALOG_DATA) public requesterData: Requester,
    private customValidationService: CustomValidationService
  ) {

  }

  ngOnInit() {
    if (!this.requesterData) {
      this.requesterData = Requester.getEmptyRequester();    
    }

    this.requesterDataLoaded = Promise.resolve(true);
  }

  save() {  
    this.appService.upsertRequesters(this.requesterData)
      .toPromise()
      .then(response => {
        this.dialogRef.close(response);
      })
      .catch(err => {
        console.log(err);
      });
  }

  close() {
    this.dialogRef.close();
  }
}
