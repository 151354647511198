import { Component, OnInit, Output, EventEmitter, OnDestroy, ViewChild } from '@angular/core';
import { AppService } from '../../../service/app.service';
import { UtilService } from '../../../service/util.service';
import { DatePipe } from "@angular/common";
import { PurchaseRequestSearchParams } from "../../../model/purchaseRequestSearchParams";
import { PurchaseRequest } from "../../../model/purchaseRequest";
import { MAT_DIALOG_DATA, MatSnackBar } from "@angular/material";
import { PurchaseRequestUpsertDialogComponent } from '../../purchase-request/upsert-dialog/purchase-request-upsert-dialog.component';
import { MatDialog, MatDialogConfig, MatDialogRef } from "@angular/material";
import { NoteDialogComponent } from '../note-dialog/note-dialog.component';
import { escapeRegExp } from '@angular/compiler/src/util';
import { ConfirmationDialogComponent } from "../../../shared/confirmation-dialog/confirmation-dialog.component";
import { Requester } from '../../../model/requester';


@Component({
  selector: 'app-purchase-request-list',
  templateUrl: './purchase-request-list.component.html',
  styleUrls: ['./purchase-request-list.component.scss']

})
export class PurchaseRequestListComponent {
  skip = 0;
  take = 20;
  pageIndex = 0;
  totalCount: number;
  count: number;
  data;
  loading = false;
  showSearchSettings = false;
  displayedColumns: string[] = [
    'Edit',
    'RequestNumber',
    'Date',
    'RequesterName',
    //'RequesterEmail',
    //'Department',
    //'RequestedItem',
    'Cost',
    'Budgeted',
    'CapitalExpenditure',
    //'VendorName',
    'ExpectedDeliveryDate',
    'PaymentTerms',
    'Status',
    'Note',
    'Delete'
  ];


  currentDialog: MatDialogRef<any>;
  sortOption = { sortBy: "RequestNumber", asc: false };

  searchStatus: string = null;
  searchRequesterName: string = null;
  searchRequestNumber: number = null;
  searchRequestEndDate: Date = null;
  searchRequestStartDate: Date = null;
  searchRequesterId: number = null;
  mobileTableMinWidth = 700;
  showMobileTable = false;

  searchRequestEndDateDateDataString: string = "";
  searchRequestStartDateDateDataString: string = "";
  showRequestersPanel: boolean = false;
  requesters;

  constructor(
    private appService: AppService,
    private dialog: MatDialog,
    public utilService: UtilService,
    public snackBar: MatSnackBar,

  ) {
    this.setShowMobileTableParams(window.innerWidth);

  }

  ngOnInit() {
    this.defaultSearch();
  }

  defaultSearch() {
    this.showSearchSettings = false;
    this.sortOption = { sortBy: "RequestNumber", asc: false };
    this.searchStatus = null;
    this.searchRequesterName = null;
    this.searchRequestNumber = null;
    this.searchRequestEndDate = null;
    this.searchRequestStartDate = null;
    this.search();
  }


  searchRequestEndDateDateDataChanged(e) {
    setTimeout(function () {
      this.searchRequestEndDateDateDataString = (e.value.getMonth() + 1) + '/' + e.value.getDate() + '/' + e.value.getFullYear();
    }.bind(this))
  }

  searchRequestStartDateDataChanged(e) {
    setTimeout(function () {
      this.searchRequestStartDateDateDataString = (e.value.getMonth() + 1) + '/' + e.value.getDate() + '/' + e.value.getFullYear();
    }.bind(this))
  }

  sort(sortBy) {

    if (this.sortOption.sortBy === sortBy)
      this.sortOption.asc = !this.sortOption.asc;
    else {
      this.skip = 0;
      this.pageIndex = 0;
      this.sortOption.sortBy = sortBy;
      this.sortOption.asc = true;
    }

    this.search();
  }

  onResize(event) {
    this.setShowMobileTableParams(event.target.innerWidth);
  }

  setShowMobileTableParams(windowWisdth) {
    this.showMobileTable = windowWisdth > this.mobileTableMinWidth ? false : true;
  }

  search() {
    this.loading = true;
    this.data = null;

    var endDate;
    var startDate;

    if (this.searchRequestStartDate) {
      startDate = new Date(this.searchRequestStartDate);
      startDate.setDate(startDate.getDate() + 1);
    }

    if (this.searchRequestEndDate) {
      endDate = new Date(this.searchRequestEndDate);
      endDate.setDate(endDate.getDate() + 1);
    }

    this.appService.listPurchaseRequests(new PurchaseRequestSearchParams(
      this.skip,
      this.take,
      this.searchRequestNumber,
      startDate,
      endDate,
      this.searchRequesterId,
      this.searchStatus,
      this.sortOption.sortBy,
      this.sortOption.asc
    ))
      .toPromise()
      .then(response => {

        this.totalCount = response.count;

        if (this.totalCount > 0) {
          var data = JSON.parse(response.data);

          this.count = data.length;
          this.data = data;
        } else {
          this.count = 0;
          //this.data = [];
        }
        this.loading = false;
      })
      .catch(err => {
        this.loading = false;
      })
  }

  changedTableCurrentPage(event) {
    this.skip = event.pageIndex * event.pageSize;
    this.pageIndex = event.pageIndex;
    this.search();
  }

  

  openUpsertPopup(data) {
  
    if (this.currentDialog)
      this.currentDialog.close();

    let dialogRef = this.dialog.open(PurchaseRequestUpsertDialogComponent, {
      data: data,
      maxHeight: 'calc(100% - 20px)',
      maxWidth: 'calc(100% - 20px)',
      height: 'auto',
      width: '800px'
    });

    this.currentDialog = dialogRef;

    dialogRef.afterClosed()
      .toPromise()
      .then(upsertSuccess => {
        if (upsertSuccess === true)
          this.defaultSearch();
      });
  }

  openNotePopup(data: PurchaseRequest) {
    if (this.currentDialog)
      this.currentDialog.close();

    let dialogRef = this.dialog.open(NoteDialogComponent, {
      data: data,
      maxHeight: 'calc(100% - 20px)',
      maxWidth: 'calc(100% - 20px)',
      height: 'auto',
      width: '800px',
      disableClose: false
    });

    this.currentDialog = dialogRef;
  }


  openDeletePopup(data: PurchaseRequest) {
    if (this.currentDialog)
      this.currentDialog.close();

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: "Are you sure you want to delete this purchase request (request number: " + data.RequestNumber + ") ?"
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {

        this.appService.deletePurchaseRequest(data.RequestNumber)
          .toPromise()
          .then(response => {

            this.snackBar.open('Purchase Request (request number: " + data.RequestNumber + ") is deleted', null, {
              verticalPosition: "top",
              duration: 3000,
              panelClass: ['success-snackbar']
            });

            this.search();

          })
          .catch(err => {
            this.snackBar.open('An error accured while deleting Purchase Request, please try again later', null, {
              verticalPosition: "top",
              duration: 3000,
              panelClass: ['error-snackbar']
            });
          });
      }
    });


    this.currentDialog = dialogRef;
  }



  toggleSearchSetting() {
    this.showSearchSettings = !this.showSearchSettings;
  }


  requestersAutoComplete(text: string) {


    text = text.trim();
    if (text.length === 0) {
      this.requesters = null;
      this.searchRequesterId = null;
      return;
    }

    this.appService.requestersAutoComplete(text)
      .toPromise()
      .then(requesters => {

        this.searchRequesterId = null;
        if (requesters === null || requesters === "") {
          this.requesters = null;
        } else {

          requesters = JSON.parse(requesters);
          if (requesters.length === 0)
            this.requesters = null;
          else {
            this.showRequestersPanel = true;
            this.requesters = requesters;
          }
        }
      });
  }

  selectRequester(requester: Requester) {
    this.searchRequesterName = requester.RequesterName;
    this.searchRequesterId = requester.RequesterId;
    this.requesters = null;
  }


}
