import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { LoginComponent } from './public/login/login.component';
import { WelcomeComponent } from './public/welcome/welcome.component';
import { ApprovePurchaseRequestComponent } from './public/approve-purchase-request/approve-purchase-request.component';
import { AdminComponent } from './secure/admin.component'


import { AuthGuard } from './guard/auth.guard';
const routes: Routes = [
 // { path: 'not-found', component: NotFoundComponent },
  {
    path: '', component: AppComponent, children: [ 
      { path: '', component: WelcomeComponent },  
      { path: 'login', component: LoginComponent } ,  
      { path: 'approve-request', component: ApprovePurchaseRequestComponent } 
    ]
  },
  {
    path: 'admin', component: AdminComponent,
    canActivate: [AuthGuard]
  }
];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
