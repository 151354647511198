//#region Imports
import { Injectable } from "@angular/core";

//#endregion
@Injectable({ providedIn: "root" })
export class UtilService {
  //#region Properties
 
  //#endregion

  //#region Constructor

  constructor(  
  ) {
   
  }

  padZeroRight(num: number, size: number): string {
    let s = num + "";
    while (s.length < size) s = s + "0";
    return s;
  }

  getCostString(cost: number) {
 
    var splt = cost.toFixed(2).toString().split('.');

    var leftNumStr = splt[0];
    var lengthMode = leftNumStr.length % 3;
    var leftNum = "";
    for (var i = 0; i < leftNumStr.length; i++) {
      leftNum += leftNumStr[i];
     
      if ((i + 1) % 3 === lengthMode)
        leftNum += ",";     
    }
    leftNum= leftNum.substr(0,leftNum.length-1);

    return leftNum + "." + splt[1];
  }

  getClearedString(str: string) {
    if (str)
      return str.replace(/\u0000/g, '');
    else
      return ""
  }

}
