export class Requester {
    RequesterId:number;   
    RequesterName:string;
    RequesterEmail:string;
    SupervisorEmail:string;   
  
    constructor(
        RequesterId:number,   
        RequesterName:string,
        RequesterEmail:string,
        SupervisorEmail:string   
    ) {
        this.RequesterId= RequesterId;
        this.RequesterName= RequesterName;
        this.RequesterEmail= RequesterEmail;
        this.SupervisorEmail= SupervisorEmail; 
    }

    static getEmptyRequester():Requester{
        return new Requester(null,"","","");
    }
}   