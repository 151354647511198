import {EventEmitter, Injectable} from '@angular/core';
import {NavigationStart, Router} from '@angular/router';
import {Subject} from 'rxjs/Subject';
import {Observable} from 'rxjs/Observable';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  private subject = new Subject<any>();
  private keepAfterNavigationChange = false;
  emitter: EventEmitter<any> = new EventEmitter();
  authEmitter: EventEmitter<any> = new EventEmitter();

  private selectedCompanySub = new BehaviorSubject(undefined);
  selectedCompany = this.selectedCompanySub.asObservable();

  childPageLoadedSub: Subject<any> = new Subject();
  childPageLoaded = this.childPageLoadedSub.asObservable();
    
  constructor(private router: Router) {
    // clear alert message on route change
    router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        if (this.keepAfterNavigationChange) {
          // only keep for a single location change
          this.keepAfterNavigationChange = false;
        } else {
          // clear alert
          this.subject.next();
        }
      }
    });
  }

  setCompany(data: any) {
    this.selectedCompanySub.next(data);
  }

  success(message: string, keepAfterNavigationChange = false) {
    this.keepAfterNavigationChange = keepAfterNavigationChange;
    this.subject.next({type: 'success', text: message});
  }

  setError(message: string, keepAfterNavigationChange = false) {
    this.keepAfterNavigationChange = keepAfterNavigationChange;   
    this.subject.next({type: 'error', text: message});
  }

  getMessage(): Observable<any> {
    return this.subject.asObservable();
  }
}
