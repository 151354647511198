import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot
} from "@angular/router";
import { Observable } from "rxjs";
import { AuthenticationService } from "../service/authentication.service";

@Injectable({
  providedIn: "root"
})
export class AuthGuard implements CanActivate, CanActivateChild {
  constructor(
    private router: Router,
    private authService: AuthenticationService
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {

  
    return this.controlAuth()
    .then(result => true)
    .catch(() => {

      this.router.navigate(["/login"], {
        queryParams: { returnUrl: state.url }
      });
      return false;
    });
  }

  controlAuth() {

    let self = this;
    return new Promise(function(resolve, reject) {
      
      self.authService
        .getUserDetails()    
        .then(response => {            
          response ? resolve() : reject();
        })
        .catch(err => {       
          reject()
        }
         );
    });
  }

  

  canActivateChild(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.canActivate(next,state);
  }

  
}
