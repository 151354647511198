import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { catchError } from 'rxjs/operators/catchError';
import { throwError, Observable, BehaviorSubject, of as observableOf } from 'rxjs';
import { map } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import { NotificationService } from './notification.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  apiUrl: string;
  public subscription$;
  private user: any;
  constructor(
    @Inject('API_URL') baseUrl: string,
    private http: HttpClient,
    private cookieService: CookieService,
    private notificationService: NotificationService
  ) {
    this.apiUrl = baseUrl;
  }

  public serviceIsActive() {

    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'X-Authorization-Token': this.cookieService.get('currentUser')
      }),
      responseType: 'text' as 'json'
    };

    return this.http.get<any>(this.apiUrl + '/api/ServiceIsActive', options);

  }


  public login(email: string, password) {
    const params = new HttpParams()
      .set('email', email)
      .set('password', password);

    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      responseType: 'text' as 'json'
    };

    return this.http.post<any>(this.apiUrl + '/api/login', {
      email: email,
      password: password
    }, options)
      .pipe(
        map(response => {

          // login successful
          if (response) {
            const dt = new Date(new Date().setHours(new Date().getHours() + 1));
            this.cookieService.set('currentUser', response.toString(), dt, '/');
          }
          return response;
        }
        ),
        catchError(err => this.handleError(err))
      );
  }

  logout() {
    localStorage.clear();
    this.cookieService.delete('currentUser', '/');    
  }

  isAuthenticated() {
    return this.cookieService.get('currentUser') !== null;
  }

  getUserDetails(forceServerCall: boolean = false) {

    return new Promise<any>(function (resolve, reject) {

      var localUser = JSON.parse(localStorage.getItem('userDetail'));
      var localUserDetailLastGetDataDateString = localStorage.getItem('userDetailLastGetDataDate');
      var localUserDetailLastGetDataDate = localUserDetailLastGetDataDateString ? new Date(localUserDetailLastGetDataDateString) : new Date();
      if (localUserDetailLastGetDataDate)
        localUserDetailLastGetDataDate.setSeconds(localUserDetailLastGetDataDate.getSeconds() + 5);

      if (localUserDetailLastGetDataDate > new Date() && forceServerCall !== true && localUser) {
         resolve(localUser);
      }
      else
        this.getUserDetailsFromServer()
          .toPromise()
          .then(user => {
            if (user) {
              localStorage.setItem('userDetail', JSON.stringify(user));
              localStorage.setItem('userDetailLastGetDataDate', (new Date()).toString());          
              resolve(user);
            } else {
              reject('Error fetching user details.');
            }
          })
          .catch(err => {
            reject(err);
          })
    }.bind(this));
  }

  getUserDetailsFromServer(): Observable<any> {

    try {
      const currentUser = this.cookieService.get('currentUser')

      if (currentUser) {
        const options = {
          headers: new HttpHeaders({
            'X-Authorization-Token': currentUser,
            'Content-Type': 'application/json'
          })
        };
        return this.http.get<any>(this.apiUrl + '/api/getLoggedInUser', options)
          .pipe(map(response => {
            var data = JSON.parse(response)[0];
            localStorage.setItem('email', data.email);
            localStorage.setItem('isAdmin', data.isAdmin);
            return data;
          }));
      } else {
        return observableOf(null);
      }
    } catch (err) {
      console.log(err);
    }
  }

  private handleError(error: HttpErrorResponse) {
    if (error.status === 400)
      return throwError(error.statusText);
    else
      return throwError('Something bad happed. Please try again.');
  }

}
