//#region node_modules packages 

//#region @angular, @ng, ngx
import { HttpClientModule, /* other http imports */ } from "@angular/common/http";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CookieService } from 'ngx-cookie-service';
//#endregion

//#region material 
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule, MatNativeDateModule } from '@angular/material';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSortModule } from '@angular/material/sort';
import { MatMenuModule } from '@angular/material/menu';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatDialogModule, MAT_DIALOG_DEFAULT_OPTIONS, MAT_DATE_LOCALE } from '@angular/material';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
//#endregion

//#endregion

//#region internal packages 

//#region secure 
import { AdminComponent } from './secure/admin.component'
import { PurchaseRequestUpsertComponent } from './secure/purchase-request/upsert/purchase-request-upsert.component';
import { PurchaseRequestUpsertDialogComponent } from './secure/purchase-request/upsert-dialog/purchase-request-upsert-dialog.component';
import { NoteDialogComponent } from './secure/purchase-request/note-dialog/note-dialog.component';
import { PurchaseRequestListComponent } from './secure/purchase-request/list/purchase-request-list.component';
import { RequestersUpsertComponent } from './secure/requesters/upsert/requesters-upsert.component';
import { RequestersListComponent } from './secure/requesters/list/requesters-list.component';

//#endregion

//#region shared 
import { ConfirmationDialogComponent } from './shared/confirmation-dialog/confirmation-dialog.component';
//#endregion

//#region public 
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './public/login/login.component'
import { WelcomeComponent } from './public/welcome/welcome.component'
import { ApprovePurchaseRequestComponent } from './public/approve-purchase-request/approve-purchase-request.component';
//#endregion

//#region service 
import { AppService } from './service/app.service';
import { NotificationService } from './service/notification.service';
import { AuthenticationService } from './service/authentication.service';
import { CustomValidationService } from './service/customValidation.service';
import { UtilService } from './service/util.service';
import { environment } from "src/environments/environment";
//#endregion

//#endregion

@NgModule({
  declarations: [
    AppComponent,
    WelcomeComponent,
    LoginComponent,
    AdminComponent,
    PurchaseRequestUpsertComponent,
    PurchaseRequestUpsertDialogComponent,
    NoteDialogComponent,
    PurchaseRequestListComponent,
    RequestersUpsertComponent,
    RequestersListComponent,
    ApprovePurchaseRequestComponent,
    ConfirmationDialogComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatTableModule,
    MatPaginatorModule,
    MatDialogModule,
    MatSlideToggleModule,
    MatInputModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatProgressSpinnerModule,
    MatExpansionModule,
    MatMenuModule,
    MatSortModule,
    MatSnackBarModule,
    MatAutocompleteModule

  ],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { hasBackdrop: false } },
    { provide: "API_URL", useFactory: getApiUrl },
    AppService,
    CookieService,
    NotificationService,
    AuthenticationService,
    CustomValidationService,
    MatDatepickerModule,
    UtilService
  ],
  bootstrap: [AppComponent],

  entryComponents: [PurchaseRequestUpsertDialogComponent, RequestersUpsertComponent,NoteDialogComponent,ConfirmationDialogComponent],
})
export class AppModule {
}
export function getApiUrl() {
  return environment.apiUrl
} 