export class RequestersSearchParams {
    skip: number;
    take: number;
    searchByRequesterName: string;
    searchByRequesterEmail: string;
    searchBySupervisorEmail: string;
    sortBy: string;// "RequestNumber"
    sortByAsc: boolean;
    constructor(
        skip: number,
        take: number,
        searchByRequesterName: string,
        searchByRequesterEmail: string,
        searchBySupervisorEmail: string,
        sortBy: string,
        sortByAsc: boolean
    ) {
        this.skip = skip;
        this.take = take;
        this.searchByRequesterName = searchByRequesterName ? searchByRequesterName : null;
        this.searchByRequesterEmail = searchByRequesterEmail ? searchByRequesterEmail : null;
        this.searchBySupervisorEmail = searchBySupervisorEmail ? searchBySupervisorEmail : null;
        this.sortBy = sortBy ? sortBy : null;
        this.sortByAsc = sortByAsc;
    }
}
