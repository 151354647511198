import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AppService } from '../../service/app.service';

@Component({
  selector: 'app-approve-purchase-request',
  templateUrl: './approve-purchase-request.component.html',
  styleUrls: ['./approve-purchase-request.component.scss']
})
export class ApprovePurchaseRequestComponent {
  title = 'MRWMD';
  inProgress: boolean = true;
  approved: boolean;
  token: string;
  response: boolean;
  requestNumber: number;
  success: boolean = false;
  constructor(
    private router: Router,
    private appService: AppService,
    private route: ActivatedRoute
  ) {

    this.route.queryParams
     
      .subscribe(params => {


        if (params['r'] === "1" || params['r'] === 1)
          this.approved = true;
        else
          this.approved = false;

        this.token = params['t'];

        this.requestNumber = parseInt(params['n']);

        this.appService.approveRequest(this.token, this.approved, this.requestNumber)
          .toPromise()
          .then(response => {
            if (response.result === true)
              this.success = true;
            else
              this.success = false;

            this.inProgress = false;
          })
          .catch(err => {
            this.inProgress = false;
            this.success = false;
          })
      });
  }
}
