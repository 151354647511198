import { AbstractControl, ValidatorFn } from '@angular/forms';
import { isNumber } from 'util';

export class CustomValidationService {

    public checkLimit(min: number, max: number): ValidatorFn {
        return (c: AbstractControl): { [key: string]: boolean } | null => {

            if (c.value && (isNaN(c.value) || c.value < min || c.value > max)) {
                return { 'range': true };
            }
            return null;
        };
    }

    public checkIsNumber(): ValidatorFn {
        return (c: AbstractControl): { [key: string]: boolean } | null => {

            if (c.value === 0)
                return null;

            if (!c.value || (isNaN(c.value) || !isNumber(c.value))) {
                return { 'notNumber': true };
            }

            return null;
        };
    }

}