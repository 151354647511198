import { Component, OnInit, Output, EventEmitter, OnDestroy, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AppService } from '../service/app.service';
import { AuthenticationService } from '../service/authentication.service';
import { DatePipe } from "@angular/common";
import { PurchaseRequestSearchParams } from "../model/purchaseRequestSearchParams";
import { AdminPages } from "../model/adminPages";
import { MatDialog, MatDialogConfig, MatMenuTrigger } from "@angular/material";
import { ConfirmationDialogComponent } from "../shared/confirmation-dialog/confirmation-dialog.component";


@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent {
  @ViewChild(MatMenuTrigger, null) trigger: MatMenuTrigger;

  adminPages;
  currentPage: AdminPages;
  hideNavBar: boolean = false;
  hideNavBarByUser: boolean = false;
  constructor(
    private appService: AppService,
    private dialog: MatDialog,
    private authenticationService: AuthenticationService,
    private router: Router
  ) {
    this.adminPages = AdminPages;
    this.currentPage = AdminPages.purchase_requests;
    this.showHideNavBar();
  }

  ngOnInit() {

  }

  openMenu() {
    this.trigger.openMenu();
  }

  toggleNavBar() {
    if (window.innerWidth < 1200)
      this.openMenu();
    else {
      this.hideNavBarByUser = !this.hideNavBarByUser;
      this.showHideNavBar();
    }
  }

  openRequesters() {
    this.currentPage = AdminPages.requesters;
  }

  openPurchaseRequests() {
    this.currentPage = AdminPages.purchase_requests;
  }


  onResize(event) {
    this.showHideNavBar();
  }

  showHideNavBar() {
    if (window.innerWidth < 1200)
      this.hideNavBar = true;
    else
      this.hideNavBar = this.hideNavBarByUser;
  }

  logout() {

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, { 
      data: "Are you sure you want to log out?"
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.authenticationService.logout();
        this.router.navigate(["/"]);
      }
    });
  }

}
