import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { FormControl, } from '@angular/forms';
import { PurchaseRequest, } from '../../../model/purchaseRequest';

@Component({
  selector: 'app-note-dialog',
  templateUrl: './note-dialog.component.html',
  styleUrls: ['./note-dialog.component.scss']

})
export class NoteDialogComponent {
  title = 'MRWMD';

  public formControl: FormControl;
  note;

  constructor(
    private dialogRef: MatDialogRef<NoteDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public noteData: PurchaseRequest,
  ) {  
    this.note = noteData.Note;
  }

  ngOnInit() {

  }

  onClose() {
    this.dialogRef.close();
  }
}
