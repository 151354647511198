//#region Imports

import { Injectable, Inject } from "@angular/core";
import { HttpClient, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { catchError } from "rxjs/operators";
import { CookieService } from "ngx-cookie-service";
import { throwError, Observable, of as observableOf } from 'rxjs';
import { PurchaseRequestSearchParams } from '../model/purchaseRequestSearchParams';
import { PurchaseRequest } from '../model/purchaseRequest';

import { RequestersSearchParams } from '../model/requestersSearchParams';
import { Requester } from '../model/requester';

//#endregion
@Injectable({ providedIn: "root" })
export class AppService {
  //#region Properties
  apiUrl: string;
  //#endregion

  //#region Constructor

  constructor(
    @Inject('API_URL') baseUrl: string,
    private http: HttpClient,
    private cookieService: CookieService,
  ) {
    this.apiUrl = baseUrl;
  }


  //#region Purchase Requests

  listPurchaseRequests(purchaseRequestSearchParams: PurchaseRequestSearchParams): Observable<any> {

    try {
      const currentUser = this.cookieService.get('currentUser')

      if (currentUser) {
        const options = {
          headers: new HttpHeaders({
            'X-Authorization-Token': currentUser,
            'Content-Type': 'application/json'
          })
        };
        return this.http.post<any>(this.apiUrl + '/api/listPurchaseRequests', purchaseRequestSearchParams, options)
          .pipe(catchError(err => this.handleError(err)));
      } else {
        return observableOf(null);
      }
    } catch (err) {
      console.log(err);
    }
  }

  upsertPurchaseRequest(purchaseRequest: PurchaseRequest) {

    try {
    
        const options = {
          headers: new HttpHeaders({       
            'Content-Type': 'application/json'
          })
        };

        let data = {
          requestData: JSON.stringify(purchaseRequest),
          updateRequestNumber: purchaseRequest.RequestNumber
        };

        return this.http.post<any>(this.apiUrl + '/api/upsertPurchaseRequest', data, options)
          .pipe(catchError(err => this.handleError(err)));
    
    } catch (err) {
      console.log(err);
    }
  }

  deletePurchaseRequest(requestNumber: number) {

    try {
      const currentUser = this.cookieService.get('currentUser')

      if (currentUser) {
        const options = {
          headers: new HttpHeaders({
            'X-Authorization-Token': currentUser,
            'Content-Type': 'application/json'
          })
        };
        
        let data = {
          requestNumber: requestNumber         
        };

        return this.http.post<any>(this.apiUrl + '/api/deletePurchaseRequest', data, options)
          .pipe(catchError(err => this.handleError(err)));
      } else {
        return observableOf(null);
      }
    } catch (err) {
      console.log(err);
    }
  }


  //#endregion

  //#region Requesters

  listRequesters(requestersSearchParams: RequestersSearchParams): Observable<any> {

    try {
      const currentUser = this.cookieService.get('currentUser')

      if (currentUser) {
        const options = {
          headers: new HttpHeaders({
            'X-Authorization-Token': currentUser,
            'Content-Type': 'application/json'
          })
        };
        return this.http.post<any>(this.apiUrl + '/api/listRequesters', requestersSearchParams, options)
          .pipe(catchError(err => this.handleError(err)));
      } else {
        return observableOf(null);
      }
    } catch (err) {
      console.log(err);
    }
  }

  upsertRequesters(requester: Requester) {

    try {
      const currentUser = this.cookieService.get('currentUser');
      if (currentUser) {
        const options = {
          headers: new HttpHeaders({
            'X-Authorization-Token': currentUser,
            'Content-Type': 'application/json'
          })
        };

        let data = {
          requesterData: JSON.stringify(requester),
          updateRequesterId: requester.RequesterId
        };

        return this.http.post<any>(this.apiUrl + '/api/upsertRequester', data, options)
          .pipe(catchError(err => this.handleError(err)));
      } else {
        return observableOf(null);
      }
    } catch (err) {
      console.log(err);
    }
  }

  requestersAutoComplete(text: string): Observable<any> {

    try {
    
        const options = {
          headers: new HttpHeaders({          
            'Content-Type': 'application/json'
          })
        };
        return this.http.post<any>(this.apiUrl + '/api/requestersAutoComplete', { text: text }, options)
          .pipe(catchError(err => this.handleError(err)));
    
    } catch (err) {
      console.log(err);
    }
  }

  approveRequest(token: string, approve: boolean, requestNumber: number): Observable<any> {

    try {

      var data = {
        token: token,
        approve: approve,
        requestNumber: requestNumber
      }

      const options = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        })
      };
      return this.http.post<any>(this.apiUrl + '/api/approveRequest',data, options)
        .pipe(catchError(err => this.handleError(err)));


    } catch (err) {
      console.log(err);
    }
  }


  //#endregion




  //#endregion

  private handleError(error: HttpErrorResponse) {
    if (error.status === 400)
      return throwError(error.statusText);
    else
      return throwError('Something bad happed. Please try again.');
  }

}
