import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../service/authentication.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {
  title = 'MRWMD';

  errorMessage;
  email = "";
  password = "";

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService
  ) {
  }

  keyPress(e) {
    if (e.code === "Enter") 
      this.login();    
  }
 

  login() {
    if (this.email.trim() === "" || this.password.trim() === "") {
      this.errorMessage = "Please enter email and password";
      return;
    }

    this.authenticationService.login(this.email, this.password)
      .toPromise()
      .then(response => {     
         this.router.navigate(["/admin"]);
      })
      .catch(err => {       
        this.errorMessage = err;
      });
  }
}
