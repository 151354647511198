export class PurchaseRequestSearchParams {
    skip: number;
    take: number;
    searchByRequestNumber: number;
    searchByRequestDateStart: Date;
    searchByRequestDateEnd: Date;
    searchByRequesterId: number;
    searchByStatus: string;
    sortBy: string;// "RequestNumber"
    sortByAsc:boolean;
    constructor(
        skip: number,
        take: number,
        searchByRequestNumber: number,
        searchByRequestDateStart: Date,
        searchByRequestDateEnd: Date,
        searchByRequesterId: number,
        searchByStatus: string,
        sortBy: string,
        sortByAsc:boolean
    ) {
        this.skip = skip;
        this.take = take;
        this.searchByRequestNumber = searchByRequestNumber?searchByRequestNumber:null;
        this.searchByRequestDateStart = searchByRequestDateStart?searchByRequestDateStart:null;
        this.searchByRequestDateEnd = searchByRequestDateEnd?searchByRequestDateEnd:null;
        this.searchByRequesterId = searchByRequesterId?searchByRequesterId:null;
        this.searchByStatus = searchByStatus?searchByStatus:null;
        this.sortBy = sortBy?sortBy:null;
        this.sortByAsc=sortByAsc;
    }
}
