import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss']
})
export class WelcomeComponent {
  title = 'MRWMD';

  constructor(
    private router: Router
  ) {
  }

  adminAccess(){
    this.router.navigate(["/admin"]);
  }
}
