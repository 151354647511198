import { Component, OnInit, Output, EventEmitter, OnDestroy, ViewChild } from '@angular/core';
import { AppService } from '../../../service/app.service';
import { DatePipe } from "@angular/common";
import { RequestersSearchParams } from "../../../model/requestersSearchParams";
import { Requester } from "../../../model/requester";


import { RequestersUpsertComponent } from '../../requesters/upsert/requesters-upsert.component';
import { MatDialog, MatDialogConfig } from "@angular/material";


@Component({
  selector: 'app-requesters-list',
  templateUrl: './requesters-list.component.html',
  styleUrls: ['./requesters-list.component.scss']

})
export class RequestersListComponent {
  skip = 0;
  take = 20;
  pageIndex = 0;
  totalCount: number;
  count: number;
  data: Array<Requester>;
  loading = false;
  showSearchSettings = false;
  displayedColumns: string[] = [
    'Edit',
       //'RequesterId', 
    'RequesterName',
    'RequesterEmail',
    'SupervisorEmail'    
  ];

  sortOption = { sortBy: "RequesterName", asc: true };

  searchStatus: string = null;
  searchRequesterName: string = null;
  searchRequesterEmail: string = null;
  searchSupervisorEmail: string = null;
  mobileTableMinWidth = 700;
  showMobileTable = false;
 

  constructor(
    private appService: AppService,
    private dialog: MatDialog
  ) {
    this.setShowMobileTableParams(window.innerWidth);
  }

  ngOnInit() {
    this.search();
  }

  sort(sortBy) {

    if (this.sortOption.sortBy === sortBy)
      this.sortOption.asc = !this.sortOption.asc;
    else {
      this.skip = 0;
      this.pageIndex = 0;
      this.sortOption.sortBy = sortBy;
      this.sortOption.asc = true;
    }

    this.search();
  }

  onResize(event) {
    this.setShowMobileTableParams(event.target.innerWidth);
  }

  setShowMobileTableParams(windowWisdth) {
    this.showMobileTable = windowWisdth > this.mobileTableMinWidth ? false : true;
  }

  search() {
    this.loading = true;
    this.data = null;
  
    this.appService.listRequesters(new RequestersSearchParams(
      this.skip,
      this.take,   
      this.searchRequesterName,
      this.searchRequesterEmail,
      this.searchSupervisorEmail,
      this.sortOption.sortBy,
      this.sortOption.asc
    ))
      .toPromise()
      .then(response => {

        this.totalCount = response.count;

        if (this.totalCount > 0) {
          var data = JSON.parse(response.data);
          this.count = data.length;
          this.data = data;
        } else {
          this.count = 0;        
        }
        this.loading = false;
      })
      .catch(err => {
        this.loading = false;
      })
  }

  changedTableCurrentPage(event) {
    this.skip = event.pageIndex * event.pageSize;
    this.pageIndex = event.pageIndex;
    this.search();
  }

  editRequester(requesterId: number) {
    let request = this.data.find(f => f.RequesterId === requesterId);
    this.openUpsertPopup(request);
  }

  openUpsertPopup(data) {

    let dialogRef = this.dialog.open(RequestersUpsertComponent, {
      data: data,
      maxHeight: 'calc(100% - 20px)',
      maxWidth: 'calc(100% - 20px)',
      height: 'auto',
      width: '800px'
    });

    dialogRef.afterClosed().subscribe(result => {

    });

  }

  toggleSearchSetting() {
    this.showSearchSettings = !this.showSearchSettings;
  }
}
