import { Component, Output, EventEmitter, Input, HostListener, OnInit, ElementRef } from '@angular/core';
import { AppService } from '../../../service/app.service';
import { UtilService } from '../../../service/util.service';
import { DatePipe } from "@angular/common";
import { MAT_DIALOG_DATA, MatSnackBar } from "@angular/material";
import { FormControl, FormsModule, Validators, ControlValueAccessor, ReactiveFormsModule, FormGroup } from '@angular/forms';
import { CustomValidationService } from '../../../service/customValidation.service';
import { PurchaseRequest } from '../../../model/purchaseRequest';
import { Departments } from '../../../model/departments';
import { Statuses } from '../../../model/statuses';
import { RequestersSearchParams } from '../../../model/requestersSearchParams';
import { Requester } from '../../../model/requester';

@Component({
  selector: 'app-purchase-request-upsert',
  templateUrl: './purchase-request-upsert.component.html',
  styleUrls: ['./purchase-request-upsert.component.scss']

})
export class PurchaseRequestUpsertComponent implements OnInit {
  title = 'MRWMD';

  @Input() purchaseRequestData: PurchaseRequest;
  @Input() isDialog: boolean = false;
  @Input() isAdminEdit:boolean;
  @Output() onClose: EventEmitter<boolean> = new EventEmitter<boolean>();

  public formControl: FormControl;
 
  description: string;
  purchaseRequestDataLoaded: Promise<boolean>;
  costValue;
  cost2Value;
  cost;
  cost2;
  requesters;
  dateString: string = "";
  expectedDeliveryDateString: string = "";
  showRequestersPanel: boolean = false;
  saving = false;
  constructor(
    private appService: AppService,
    private customValidationService: CustomValidationService,
    public snackBar: MatSnackBar,
    private eRef: ElementRef,
    public utilService:UtilService
  ) {

  }




  ngOnInit() {

    if (!this.purchaseRequestData) {
      this.purchaseRequestData = PurchaseRequest.getEmptyPurchaseRequest();
      this.purchaseRequestData.Status = 'Pending';
    }


    this.purchaseRequestData.PaymentTerms = this.purchaseRequestData.PaymentTerms.replace(/\u0000/g, '');


    var costStr = this.purchaseRequestData.Cost.toString();
    var costSplit = costStr.split('.');

    this.costValue = parseInt(costSplit[0]);
    if (costSplit.length > 1)
      this.cost2Value = parseInt(costSplit[1]);
    else
      this.cost2Value = 0;


    this.cost = new FormControl('', [
      this.customValidationService.checkIsNumber(),
      this.customValidationService.checkLimit(0, 99999999),
    ]);

    this.cost2 = new FormControl('', [
      this.customValidationService.checkIsNumber(),
      this.customValidationService.checkLimit(0, 99)
    ]);

    this.purchaseRequestDataLoaded = Promise.resolve(true);

    this.requestDatePickerDataChanged({ value: new Date() })
    this.expectedDeliveryDatePickerDataChanged({ value: new Date() })

  }

  onCostFocus(event) {
    if (event.target.valueAsNumber === 0)
      this.costValue = "";
  }
  onCost2Focus(event) {
    if (event.target.valueAsNumber === 0)
      this.cost2Value = "";
  }

  onCost2Blur(event) {

    if (!event.target.valueAsNumber)
      this.cost2Value = 0;
    else 
      this.cost2Value = parseInt(this.utilService.padZeroRight(event.target.valueAsNumber, 2));
    
  }

  onCostBlur(event) {
    if (!event.target.valueAsNumber)
      this.costValue = 0;   
  }

  initPurchaseRequestData() {

  }

  budgetedChanged(e) {
    this.purchaseRequestData.Budgeted = e.checked;
  }

  capitalExpenditureChanged(e) {
    this.purchaseRequestData.CapitalExpenditure = e.checked;
  }

  requestersAutoComplete(text: string) {
    text = text.trim();
    if (text.length === 0) {
      this.requesters = null;
      return;
    }


    this.appService.requestersAutoComplete(text)
      .toPromise()
      .then(requesters => {

        this.purchaseRequestData.RequesterId = null;
        if (requesters === null || requesters === "") {
          this.requesters = null;
        } else {

          requesters = JSON.parse(requesters);
          if (requesters.length === 0)
            this.requesters = null;
          else {
            this.showRequestersPanel = true;
            this.requesters = requesters;
          }

        }
      });

  }

  selectRequester(requester: Requester) {
    this.purchaseRequestData.RequesterName = requester.RequesterName;
    this.purchaseRequestData.RequesterId = requester.RequesterId;
    this.requesters = null;
  }


  save() {

    if (
      this.cost.errors ||
       this.cost2.errors ||
       this.purchaseRequestData.PaymentTerms.trim()==="" ||
       this.purchaseRequestData.RequestedItem.trim()==="" ||
       this.purchaseRequestData.RequestedItem.trim()==="" ||
       this.purchaseRequestData.VendorName.trim()===""   ||  
       this.purchaseRequestData.RequesterId===null
       
       ) {
      this.snackBar.open('Invalid inputs! Please check your inputs, fill all required fields and try again', null, {
        verticalPosition: "top",
        duration: 2000,
        panelClass: ['error-snackbar']
      });
      return;
    }


    this.saving = true;
    this.snackBar.open('Purchase Request will save please wait', null, {
      verticalPosition: "top",
      duration: 2000,
      panelClass: ['warning-snackbar']
    });

    this.purchaseRequestData.Cost = parseFloat(this.costValue + "." + this.cost2Value);

    this.appService.upsertPurchaseRequest(this.purchaseRequestData)
      .toPromise()
      .then(response => {
        this.onClose.emit(true);

        this.snackBar.open('Purchase Request is saved', null, {
          verticalPosition: "top",
          duration: 3000,
          panelClass: ['success-snackbar']
        });

        this.dispose();
      })
      .catch(err => {

        this.snackBar.open('An error accured while saving Purchase Request, please control all the fields and try again', null, {
          verticalPosition: "top",
          duration: 3000,
          panelClass: ['error-snackbar']
        });
        this.saving = false;
      });
  }

  close() {
    this.onClose.emit(false);
  }

  onCostChange() {

  }

  @HostListener('document:click', ['$event'])
  clickout(event) {
    this.showRequestersPanel = false;
  }

  getDate() {
    return (this.purchaseRequestData.Date.getMonth() + 1) + '/' + this.purchaseRequestData.Date.getDate() + '/' + this.purchaseRequestData.Date.getFullYear();

  }

  requestDatePickerDataChanged(e) {
    setTimeout(function () {
      this.dateString = (e.value.getMonth() + 1) + '/' + e.value.getDate() + '/' + e.value.getFullYear();
    }.bind(this))
  }

  expectedDeliveryDatePickerDataChanged(e) {
    setTimeout(function () {
      this.expectedDeliveryDateString = (e.value.getMonth() + 1) + '/' + e.value.getDate() + '/' + e.value.getFullYear();
    }.bind(this))
  }

  dispose() {
    this.purchaseRequestData.Budgeted = false;
    this.purchaseRequestData.CapitalExpenditure = false;
    this.costValue = null;
    this.cost2Value = null;
    this.purchaseRequestData.Date = new Date();
    this.purchaseRequestData.ExpectedDeliveryDate = new Date();
    this.purchaseRequestData.PaymentTerms = null;
    this.purchaseRequestData.RequestedItem = "";
    this.purchaseRequestData.RequesterName = "";
    this.purchaseRequestData.Status = 'Pending';
    this.purchaseRequestData.VendorName = "";
    this.purchaseRequestData.Note = "";
    this.saving = false;
  }

 
}
