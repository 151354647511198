import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { FormControl, } from '@angular/forms';
import { PurchaseRequest } from '../../../model/purchaseRequest';

@Component({
  selector: 'app-purchase-request-upsert-dialog',
  templateUrl: './purchase-request-upsert-dialog.component.html',
  styleUrls: ['./purchase-request-upsert-dialog.component.scss']

})
export class PurchaseRequestUpsertDialogComponent {
  title = 'MRWMD';

  public formControl: FormControl;
  description: string;
  purchaseRequestData;
  isAdminEdit: boolean=false;

  constructor(
    private dialogRef: MatDialogRef<PurchaseRequestUpsertDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public requesterData: PurchaseRequest,
  ) {
    this.purchaseRequestData = requesterData;
    if (this.purchaseRequestData)
      this.isAdminEdit = true;
  }

  ngOnInit() {

  }

  onClose(upsertSuccess: boolean) {
    this.dialogRef.close(upsertSuccess);
  }
}
